import { isDemo } from "../config/env";

export const ORS_DEPRECATION_FC_KEY = isDemo
  ? "b2b-ors-deprecation-demo"
  : "b2b-ors-deprecation";

export const FC_DOCUMENT_TYPE_NEW_BEHAVIOR = "b2b-document-type-new-behavior";

export const FC_RECENT_SEARCH = "b2b-recent-search";

export const FC_ENABLE_DEVTOOLS = "ctv-enabled-devtools";

export const FC_INVOICE_DISPUTE = "ctv-invoice-dispute";

export const FC_TERMS_AND_CONDITIONS_V2 = "b2b-terms-and-conditions-v2";

export const FC_FLIGHT_CONNECTING_ONLY = "b2b-flight-connecting-only";

export const FC_MFA_GATEWAY = "b2b-mfa-gateway";

/**
 * @todo Remove this constant after all usages are removed
 * https://29022131.atlassian.net/browse/CTV-7454
 */
export const FC_INSURANCE = "ctv-insurance";

export default {
  ORS_DEPRECATION_FC_KEY,
  FC_DOCUMENT_TYPE_NEW_BEHAVIOR,
  FC_RECENT_SEARCH,
  FC_ENABLE_DEVTOOLS,
  FC_INVOICE_DISPUTE,
  FC_TERMS_AND_CONDITIONS_V2,
  FC_MFA_GATEWAY,
  FC_FLIGHT_CONNECTING_ONLY,
};
